import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useTranslation } from "react-i18next";
import { Form, Formik } from "formik";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import * as FileSaver from "file-saver";
import { ResourceLoading } from "./ResourceLoading";
import { mapErrors } from "../Utils";
import PMListboxFormik from "./PMListboxFormik";
import { Print } from "@mui/icons-material";
import { ResourceError } from "./ResourceError";

export const PrintDialog = (props) => {
  const { onLoading, onClose, open, item, axiosMethod, module } = props;
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const initialValues = {
    id: item?.id,
    print: true,
    type: "",
    submit: null,
  };

  // format type options from props.typeOptions - add name to all options before setting as state
  const formatTypeOptions = (options) => {
    return options.map((option) => {
      return {
        ...option,
        name: "type",
      };
    });
  };

  const [typeOptions, setTypeOptions] = useState([]);

  useEffect(() => {
    if (props.typeOptions) {
      const formattedOptions = formatTypeOptions(props.typeOptions);
      setTypeOptions(formattedOptions);
    }
  }, [props.typeOptions]);

  const [downloadData, setDownloadData] = useState();
  const [pdfUrl, setPdfUrl] = useState(null);
  const [type, setType] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (downloadData) {
      FileSaver.saveAs(
        downloadData,
        `${module}-${item?.id}-${item?.report_type}.pdf`
      );
    }
  }, [downloadData]);

  useEffect(() => {
    if (!open) {
      setError(null);
      setPdfUrl(null);
      setType(null);
      setIsLoading(false);
    }
  }, [open]);

  const handleTypeChange = (data) => {
    if (!data) return;
    setType(data.value);
  };

  const handleDownloadPDF = () => {
    // Open pdfUrl in another window
    window.open(pdfUrl, "_blank");
  };

  const fetchPdfReport = async () => {
    setIsLoading(true);
    setError(null); // Reset error state before making the request

    if (!item?.id) {
      console.error("Item ID is missing.");
      setError("Item ID is missing.");
      setIsLoading(false);
      return false;
    }

    const payload = {
      id: item?.id,
      printType: type,
    };

    try {
      const response = await axiosMethod(payload);

      if (response?.data?.pdf_url) {
        setPdfUrl(response.data.pdf_url);
      } else {
        console.error("PDF URL is missing in the response.");
        setError("PDF URL is missing in the response.");
      }
    } catch (err) {
      console.error("Error fetching PDF report:", err);
      setError(err?.response?.data?.message || "An unexpected error occurred.");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (type) {
      fetchPdfReport();
    }
  }, [type]);

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={onClose}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="w-full sm:max-w-2xl max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <div className="mt-3 sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900 "
                    >
                      {t("Print") +
                        " " +
                        t(module.charAt(0).toUpperCase() + module.slice(1))}
                    </Dialog.Title>

                    {isLoading ? (
                      <ResourceLoading />
                    ) : !item?.id ? (
                      "Missing ID"
                    ) : (
                      <div>
                        {error && (
                          <div className="mt-10">
                            <ResourceError />
                          </div>
                        )}
                        <div className="grid grid-cols-6 gap-6 mt-10">
                          <div className="col-span-6 sm:col-span-6">
                            <PMListboxFormik
                              label={t("Type")}
                              name="type"
                              onChange={handleTypeChange}
                              options={typeOptions}
                              selected={null}
                            />
                          </div>
                          <div className="col-span-6 sm:col-span-6 mb-20">
                            {pdfUrl && (
                              <button
                                type="button"
                                className="w-full justify-center rounded-md border border-transparent bg-emerald-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
                                onClick={handleDownloadPDF}
                              >
                                {t("Download")}
                              </button>
                            )}
                          </div>
                        </div>
                        <div className="mt-20 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                          <button
                            type="button"
                            className="inline-flex justify-center w-20 rounded-md border border-transparent bg-gray-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
                            onClick={onClose}
                          >
                            {t("Close")}{" "}
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};
